import WhoWeAreImg1 from '../../public/assets/images/about/About_Us_2.png';
import WhoWeAreImg2 from '../../public/assets/images/about/About_Us_3.png';
import { BsPostcard } from 'react-icons/bs';
import { RiUserStarLine } from 'react-icons/ri';

export const aboutUsData = {
  aboutId: 101,
  aboutTitle: 'About TechWizard.',
  aboutImage: [WhoWeAreImg1, WhoWeAreImg2],
  aboutDescription:
    'Alpine Pro Health is a premier medical coding company of excellence and distinction in services to the US healthcare industry. It delivers high-quality medical coding services and Revenue Cycle Management (RCM) solutions with accuracy and specificity for compliance services. To our clients, we promise precise coding practices and very tight turnaround times to ensure that the results are consistent at the top tier, creating financial integrity and operational efficiency.',
  keyPoints: [
    {
      icon: BsPostcard,
      title: 'International Initiatives',
      description: 'An innovative partnership fostering collaboration and continuous improvement.',
    },
    {
      icon: RiUserStarLine,
      title: 'Client Satisfaction',
      description: 'Reaching 100 % for several projects, results have been successful.',
    },
  ],
  ourMission: [
    {
      aboutId: 102,
      aboutTitle: 'Our Mission',
      aboutDescription:
        'Alpine Pro Health is committed to transforming healthcare by delivering superior medical coding solutions. We simplify documentation, support providers in reaching their financial goals, and ensure that patient care remains uncompromised.',
    },
    {
      aboutId: 103,
      aboutTitle: 'Our Vision',
      aboutDescription:
        'We aim to reduce administrative burdens for healthcare providers, enabling them to concentrate on delivering high-quality care. By pioneering innovative medical coding solutions and services, we strive to set new industry standards.',
    },
  ],
};
