import Title from "../common/Title";
import { counterData } from "./countData";
import ProjectCounter from "./ProjectCounter";

const AboutCounter = () => {
  return (
    <div className="counter">
      
      {/* <video autoPlay muted loop className="video-background">
        <source src="/assets/videos/about_bg_vid.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video> */}

      <div className="container md:py-24 py-12 z-20 relative text-white">
        {/* CONTENT */}
        <div>
          <Title
            subTitle="Explore Our Distinctive Qualities"
            title="Skills and experience that shape tomorrow's technologies"
            primary={false}
            classNames="text-left justify-start items-start mb-6 text-primary"
            subTitleColor={"text-white"}
          />
          <p className="lg:text-xl text-lg text-gray-300">
            {/*  eslint-disable-next-line react/no-unescaped-entities */}
            Alpine Pro Health consists of a competent group of medical coding
            professionals—with enough experience in ICD-10 CM, CPT, and most
            recently, advanced training in HCC Coding—assuring only the best
            quality of accurate and compliant quality assurance. Excellence is
            what we aim for concerning patient data.
          </p>

          {/* <p className="lg:text-4xl text-2xl font-medium mt-10">
            We've empowered tech businesses to achieve remarkable growth, with average revenue increases.
          </p> */}
        </div>
        {/* CONTENT */}
        {/* COUNTER */}
        {/* <div>
        <ul className="w-full grid md:grid-cols-2 xl:gap-5 gap-6 rounded-3xl">
          {counterData?.map((count) => (  
          <ProjectCounter key={count?.countId} count={count} /> 
          ))}
        </ul>
          </div> */}
        {/* COUNTER */}
      </div>
    </div>
  );
};

export default AboutCounter;
