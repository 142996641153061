import { useLocation } from "react-router-dom";
import { TabSlider } from "../TabSlider";
import { AnimatePresence, motion } from "framer-motion";
import SubService from "./SubService";
import RCMGif from "../../public/assets/videos/RCM_Resize.gif";

const SubServices = ({
  filteredServiceData,
  currentTab,
  setCurrentTab,
  categories,
  currentServiceDescription,
}) => {
  const location = useLocation();
  const isRevenueCycleManagement =
    location.pathname === "/services/revenue-cycle-management";
  return (
    <div>
      <div className="container">
        <TabSlider
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          tabItems={categories}
        />

        {/* Description */}
        <p className="text-justify text-lg">{currentServiceDescription}</p>

        {/* Conditionally Render GIF */}
        {isRevenueCycleManagement && (
          <div className="flex justify-center my-6 sm:my-4">
            <img
              src={RCMGif}
              alt="Revenue Cycle Management"
              className=""
            />
          </div>
        )}
        {/* Sub Services */}
        <motion.div
          layout
          className="grid lg:grid-cols-5 sm:grid-cols-2 lg:gap-10 gap-5 my-12"
        >
          <AnimatePresence>
            {filteredServiceData.map((detail, i) => (
              <SubService item={detail} key={i} />
            ))}
          </AnimatePresence>
        </motion.div>
      </div>
    </div>
  );
};

export default SubServices;
