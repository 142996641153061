export const headerMenuItems = [
  //HOME 1 and 2 Future we need

  /*
  {
    text: 'Home',
    link: '',
    dropdownMenu: [
      { text: 'Home Version One', link: '/' },
      { text: 'Home Version Two', link: '/home-two' },
    ],
  },
*/
  //SINGLE HOME2
  /*
  {
    text: 'Home',
    link: 'home-two', 
  },
  */
  //SINGLE HOME1
  {
    text: "Home",
    link: "",
  },

  {
    text: "About",
    link: "about-us",
    /*
    dropdownMenu: [
      { text: 'About Us', link: '/about' },
      { text: 'Why Choose Us', link: '/about/why-choose-us' },
      { text: 'Management Message', link: '/about/management-message' },
      { text: 'Our Team', link: '/about/our-team' },
      { text: 'Single Team', link: '/about/our-team/web-dev' },
      { text: 'Single Member', link: '/about/our-team/web-dev/john-doe' },
      { text: 'Life at TechWizard', link: '/about/gallery' },
      { text: 'Single Album', link: '/about/gallery/our-office' },
    ],
    */
  },
  {
    text: "Services",
    link: "services",
    dropdownMenu: [
      {
        text: "Inpatient Medical Coding Services",
        link: "/services/inpatient-medical-coding-services",
        // link: "services",
      },
      {
        text: "Outpatient Medical Coding Services",
        link: "/services/outpatient-medical-coding-services",
        // link: "services",
      },
      // Medical Coding
      {
        text: "Risk Adjustment",
        link: "/services/risk-adjustment",
        // link: "services",
      },
      // Evaluation Management
      // {
      //   text: 'Risk Adjustment Coding',
      //   link: '/services/risk-adjustment-coding',
      // },
      // Risk Adjustment Coding
      {
        text: "Value Added Services",
        link: "/services/value-added-services",
        // link: "services",
      },
      // Facility Oriented Coding
      {
        text: "Revenue Cycle Management",
        link: "/services/revenue-cycle-management",
        // link: "services",
      },
      // Professional Coding
      {
        text: "Physician Quality Reporting",
        link: "/services/physician-quality-reporting",
        // link: "services",
      },
      /*
      {
        text: 'Digital Agency',
        link: '/services/digital-agency',
      },
      */
    ],
  },
  {
    text: "Career",
    link: "career",
    // dropdownMenu: [
    //   { text: "Career", link: "/career" },
    //   // { text: "Single Career", link: "/" },
    // ],
  },
  /*
  {
    text: 'Portfolio',
    link: 'portfolio',
    dropdownMenu: [
      { text: 'Portfolio', link: '/portfolio' },
      { text: 'Portfolio Masonry', link: '/portfolio-masonry' },
      { text: 'Portfolio Details', link: '/portfolio/online-bookstore' },
    ],
  },
  */
  {
    text: "Resources",
    link: "https://alpineprohealth.com/blog/",
    dropdownMenu: [
      { text: "Blog", link: "https://alpineprohealth.com/blog/" },
      // {
      //   text: "Case Study",
      //   // link: "/blog/the-ever-expanding-horizons-of-web-development",
      //   link: "/",
      // },
      // {
      //   text: "Infographics",
      //   link: "/",
      // },
    ],
  },
  // {
  //   text: 'Pages',
  //   link: '#',
  //   dropdownMenu: [
  //     // { text: 'Login', link: '/login' },
  //     // { text: 'Register', link: '/register' },
  //     // { text: 'Faq', link: '/faq' },
  //     // { text: 'Not Found Page', link: '/not-found' },
  //     // { text: 'Terms and Conditions', link: '/terms-and-conditions' },
  //     { text: 'Privacy Policy', link: '/privacy-policy' },
  //   ],
  // },
  {
    text: "Contact Us",
    // link: "contact",
    link: "contact",
  },
];
