import { IoCall, IoMail } from 'react-icons/io5';
import SocialButton from '../SocialButton';
import { FaMapMarkedAlt } from 'react-icons/fa';

import { MdLocationCity, MdMail } from "react-icons/md";
import { BsFillPhoneVibrateFill } from "react-icons/bs";

const ContactInfo = () => {
  const contactData = {
    contactPhone: '+91 8519899754',
    contactPhone2: '+1(914)-292-9596',
    contactEmail: 'info@alpineprohealth.com',
    contactAddress: '97, 98, Level 1, Magna Square, Jawaharlal Nehru Rd, Ashok Nagar, Chennai, Tamil Nadu, 600083, India',
    contactAddress2: 'No.81/81, Level 1, Vellalar St, Mogappair West, Chennai, Tamil Nadu 600037, India',
  };

  const socialLinks = [
    {
      href: 'https://www.linkedin.com/company/alpine-pro-health-llp/',
      name: 'linkedin',
    },
    {
      href: 'https://www.instagram.com/alpine_pro_health/',
      name: 'instagram',
    },
    {
      href: 'https://www.facebook.com/alpineprohealth',
      name: 'facebook',
    },
  ];

  return (
    <div>
      <div className="space-y-3">
        <h4 className="text-3xl font-medium">CONTACT INFO</h4>
        <p className="text-lg text-darkGray">
          Welcome to our Website. We are glad to have you around.
        </p>
      </div>

      <hr className="w-[85px] h-px bg-darkGray my-5" />
      {/* INFO */}
      <div className="flex justify-between flex-wrap gap-14">
        <div className="flex items-center gap-8 flex-grow">
          <IoCall className="text-5xl text-primary" />
          <div className="text-lg">
            <p>India</p>
            <a href={`tel:${contactData?.contactPhone}`} className="text-darkGray inline-block">
              {contactData?.contactPhone}
            </a>
          </div>
        </div>
        <div className="flex items-center gap-8 flex-grow">
          <BsFillPhoneVibrateFill className="text-5xl text-primary" />
          <div className="text-lg">
            <p>United States</p>
            <a href={`tel:${contactData?.contactPhone2}`} className="text-darkGray inline-block">
              {contactData?.contactPhone2}
            </a>
          </div>
        </div>

        <div className="flex items-center gap-8 flex-grow">
          <IoMail className="text-5xl text-primary" />
          <div className="text-lg">
            <p>Email</p>
            <a href={`mailto:${contactData?.contactEmail}`} className="text-darkGray inline-block">
              {contactData?.contactEmail}
            </a>
          </div>
        </div>

        <div className="flex gap-8 flex-grow">
          <FaMapMarkedAlt className="text-5xl text-primary" />
          <div className="text-lg">
            <p>Phase 1</p>
            <p className="text-darkGray">{contactData?.contactAddress}</p>
          </div>
        </div>

        <div className="flex gap-8 flex-grow">
          <MdLocationCity className="text-5xl text-primary" />
          <div className="text-lg">
            <p>Phase 2</p>
            <p className="text-darkGray">{contactData?.contactAddress2}</p>
          </div>
        </div>
      </div>
      {/* INFO */}

      <hr className="w-full h-px bg-darkGray bg-opacity-30 lg:my-12 my-7" />

      {/* SOCIAL ICONS */}
      <ul className="flex items-center gap-5 -mt-4">
        {socialLinks.map((link, i) => (
          <SocialButton
            key={i}
            icon={link?.icon}
            href={link?.href}
            name={link?.name}
            classNames="border border-gray-300"
            i={i}
          />
        ))}
      </ul>
      {/* SOCIAL ICONS */}
    </div>
  );
};

export default ContactInfo;
