import { useEffect, useRef, useState } from "react";
import { LoadingButton } from "../../components/Button";
import Input, { Textarea } from "../../components/Input";
import Checkbox from "../../components/Checkbox";
// import Select from "../../components/Select";
import useSticky from "../../hooks/useSticky";
import { useModal } from "../../context/ModalContext";
import { twMerge } from "tailwind-merge";
import { MdClose } from "react-icons/md";
import { FaRegPaperPlane } from "react-icons/fa";
import { AiOutlineCloudUpload } from "react-icons/ai";
import ThumbsUp from "../../public/assets/images/career/thumbs-up.png";
import Swal from "sweetalert2";
import { BsFileEarmarkFill } from "react-icons/bs";

function HireModal() {
  // const budgetOptions = [
  //   { value: "just got started", label: "Just got started" },
  //   { value: "25k - 75k", label: "25k - 75k" },
  //   { value: "75k - 150k", label: "75k - 150k" },
  //   { value: "150k - 300k", label: "150k - 300k" },
  //   { value: "300k+", label: "300k+" },
  // ];

  const findOptions = [
    { value: "google search", label: "Google Search" },
    { value: "clutch", label: "Clutch" },
    { value: "upcity", label: "Upcity" },
    { value: "facebook ad", label: "Facebook Ad" },
    { value: "linkedin", label: "Linkedin" },
    { value: "refarrel", label: "Refarrel" },
    { value: "other", label: "Other" },
  ];

  // const defaultOpt = {
  //   value: "",
  //   label: "Please Select",
  // };

  const initialData = {
    firstName: "",
    lastName: "",
    email: "",
    number: "",
    company: "",
    website: "",
    message: "",
    message2: "",
    businessClassification: [],
    file: "",
    // budget: "",
    // findOption: "",
    // lookingFor: [],
  };

  // const [budgetSelect, setBudgetSelect] = useState("");
  // const [findSelect, setFindSelect] = useState("");
  // const [businessClassification, setBusinessClassification] = useState([]);
  // const [lookingFor, setLookingFor] = useState([]);
  const [formData, setFormData] = useState(initialData);
  const [file, setFile] = useState(null);
  const [previewImg, setPreviewImg] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [successSubmitted, setSuccessSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { sticky } = useSticky(300);
  const { isModalOpen, setIsModalOpen } = useModal();
  const formRef = useRef(null);

  const handleFormChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleClassificationCheckboxChange = (e) => {
    const updatedClassifications = e.target.checked
      ? [...formData.businessClassification, e.target.value]
      : formData.businessClassification.filter((val) => val !== e.target.value);

    setFormData({
      ...formData,
      businessClassification: updatedClassifications,
    });
  };

  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      const allowedTypes = ["image/png", "image/jpeg", "application/pdf"];
      const maxSize = 5 * 1024 * 1024;

      if (uploadedFile.size > maxSize) {
        Swal.fire({
          icon: "error",
          title: "File size too large",
          text: "Please upload a file smaller than 5MB.",
        });
        return;
      }

      if (!allowedTypes.includes(uploadedFile.type)) {
        Swal.fire({
          icon: "error",
          title: "Invalid file type",
          text: "Only images (PNG, JPG) and PDF files are allowed.",
        });
        return;
      }

      setFile(uploadedFile);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (value) data.append(key, value);
    });
    if (file) data.append("file", file);

    try {
      const response = await fetch(
        "https://alpineprohealth.com/sharmi/get_quotation.php",
        {
          method: "POST",
          body: data,
        }
      );

      if (!response.ok) throw new Error("Network response was not ok");

      await response.json();
      setSuccessSubmitted(true);
      Swal.fire({
        title: "Success!",
        text: "Your application has been submitted successfully.",
        icon: "success",
        confirmButtonText: "OK",
      });
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: error.message || "Something went wrong. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    formRef.current?.reset();
    setPreviewImg(null);
    setSuccessSubmitted(false);
    setIsModalOpen(false);
  };

  useEffect(() => {
    setDisabled(!(formData.firstName && formData.number && formData.email));
  }, [formData]);

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setPreviewImg(reader.result);
      reader.readAsDataURL(file);
    }
  }, [file]);

  return (
    <div>
      {successSubmitted && (
        <div
          className={twMerge(
            "fixed z-50 inset-0 transition-all duration-500 hide-scrollbar grid place-items-center",
            !successSubmitted ? "opacity-0 invisible" : "opacity-100 visible"
          )}
        >
          <div
            className="fixed inset-0 transition-opacity"
            onClick={handleClose}
          >
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <div className="max-w-2xl w-full m-auto grid place-items-center p-12 gap-4 bg-white rounded-md shadow-md relative">
            <button
              onClick={handleClose}
              className="absolute right-4 top-4 text-gray-700"
            >
              <MdClose className="text-4xl" />
            </button>

            <img src={ThumbsUp} alt="thumbs-up" className="w-20 h-auto" />

            <h4 className="text-3xl font-medium">Thanks for the Apply!</h4>
            <p className="text-darkGray text-lg">
              We will contact with you shortly.
            </p>
          </div>
        </div>
      )}

      <div
        className={twMerge(
          "z-50 fixed right-0 bottom-1/3 flex flex-col gap-1 transition-all duration-500 group",
          sticky
            ? "translate-x-[65%] hover:translate-x-0 opacity-100 visible"
            : "translate-x-full opacity-0 invisible"
        )}
      >
        <button
          className={`text-xl tracking-widest text-white flex items-center group`}
          onClick={() => setIsModalOpen(true)}
        >
          <span className="text-white text-3xl bg-primary px-4 py-3 transition-all">
            <FaRegPaperPlane />
          </span>
          <span className="opacity-0 group-hover:opacity-100 transition-all duration-500 bg-primary pr-4 py-[13px]">
            Get Quote
          </span>
        </button>
      </div>

      <div
        style={{ scrollbarWidth: "none" }}
        className={twMerge(
          "fixed z-50 inset-0 transition-all duration-500 hide-scrollbar",
          !isModalOpen ? "opacity-0 invisible" : "opacity-100 visible"
        )}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="fixed inset-0 transition-opacity"
            onClick={handleClose}
          >
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full relative">
            <button
              onClick={handleClose}
              className="absolute right-4 top-4 text-gray-700"
            >
              <MdClose className="text-4xl" />
            </button>
            <div className="bg-white sm:p-12 p-8 pt-14">
              {/* TITLE START */}
              <h2 className="text-4xl leading-6 font-bold text-center text-primary mb-4">
                WORK WITH US
              </h2>
              <p className="text-lg text-center sm:mb-12 mb-6 text-gray-500">
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                NOW, THAT'S SOME SMART THINKING.
              </p>
              {/* TITLE END */}

              <form onSubmit={handleSubmit} ref={formRef}>
                {/* TEXT INPUTS */}
                <div className="grid md:grid-cols-2 md:gap-6">
                  <Input
                    type="text"
                    id="firstName"
                    label="Your first name"
                    placeholder="Jhon"
                    errorMessage="This field is required!"
                    onChange={handleFormChange}
                    required
                  />

                  <Input
                    type="text"
                    id="lastName"
                    label="Your last name"
                    placeholder="Doe"
                    onChange={handleFormChange}
                  />
                </div>

                <div className="grid md:grid-cols-2 md:gap-6">
                  <Input
                    type="email"
                    id="email"
                    label="Your email address"
                    placeholder="example@gmail.com"
                    errorMessage="This field is required!"
                    onChange={handleFormChange}
                    required
                  />
                  <Input
                    type="text"
                    id="number"
                    label="Your phone number"
                    placeholder="+123456789"
                    errorMessage="This field is required!"
                    onChange={handleFormChange}
                    required
                  />
                </div>

                <div className="grid md:grid-cols-2 md:gap-6">
                  <Input
                    type="text"
                    id="company"
                    label="Your company name"
                    placeholder="example"
                    onChange={handleFormChange}
                  />
                  <Input
                    type="text"
                    id="website"
                    label="Your company website"
                    placeholder="www.example.com"
                    onChange={handleFormChange}
                  />
                </div>

                <Textarea
                  style={{ resize: "none" }}
                  id="message"
                  label="Additional message (optional)"
                  placeholder="Your message..."
                  onChange={handleFormChange}
                />

                <Textarea
                  style={{ resize: "none" }}
                  id="message2"
                  label="How Do You Know Us?"
                  placeholder="Your message..."
                  onChange={handleFormChange}
                />

                {/* CHECKBOXES */}
                <div className="flex gap-4 mt-4">
                  <div className="flex flex-col gap-2">
                    <label className="font-bold">
                      Business Classifications
                    </label>
                    <div className="flex flex-wrap items-center gap-x-4 gap-y-2 mt-4">
                      {findOptions.map((option) => (
                        <Checkbox
                          key={option.value}
                          value={option.value}
                          label={option.label}
                          onChange={handleClassificationCheckboxChange}
                        />
                      ))}
                    </div>
                  </div>
                </div>

                {/* FILE UPLOAD */}
                <div className="mt-4">
                  <label className="block mb-2 font-bold">
                    Upload Files (optional)
                  </label>
                  <div className="flex items-center gap-4">
                    <input
                      htmlFor="resume"
                      type="file"
                      id="resume"
                      onChange={handleFileChange}
                      accept=".jpg,.jpeg,.png,.pdf"
                      className="hidden"
                      required
                    />
                    <label
                      htmlFor="resume"
                      className="flex items-center cursor-pointer bg-gray-100 p-3 rounded-lg border border-gray-300"
                    >
                      <BsFileEarmarkFill className="mr-2 text-primary" />
                      Upload Resume
                    </label>

                    {file && (
                      <div className="mt-2">
                        {file.type === "application/pdf" ? (
                          <div className="flex items-center space-x-2">
                            <AiOutlineCloudUpload className="text-primary text-2xl" />
                            <span className="text-sm text-gray-700">
                              {file.name} (PDF)
                            </span>
                          </div>
                        ) : (
                          <img
                            src={previewImg}
                            alt="File Preview"
                            className="w-32 h-32 object-cover rounded-lg"
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex items-center gap-8 justify-between mt-5">
                  {loading ? (
                    <LoadingButton />
                  ) : (
                    <button
                      type="submit"
                      className={twMerge(
                        "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white sm:w-auto sm:text-sm",
                        disabled
                          ? "bg-gray-400"
                          : "bg-primary hover:bg-indigo-600"
                      )}
                      disabled={disabled}
                    >
                      Submit
                    </button>
                  )}

                  <button
                    onClick={handleClose}
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 sm:mt-0 sm:w-auto sm:text-sm"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HireModal;
