"use client";

import { Breadcrumb } from "../common/Breadcrumb";

import Loader from "../common/Loader";
import useLoader from "../../hooks/useLoader";
import ContactInfo from "./ContactInfo";
import ContactForm from "./ContactForm";

const Contact = () => {
  const loading = useLoader();

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <Breadcrumb title="Contact" />

      <div className="py-14">
        <div className="container">
          {/* CONTACT INFO & FORM */}
          <div className="grid lg:grid-cols-2 gap-20">
            {/* CONTACT INFO */}
            <ContactInfo />
            {/* CONTACT INFO */}

            {/* CONTACT FORM */}
            <ContactForm />
            {/* CONTACT FORM */}
          </div>
          {/* CONTACT DETAILS & FORM */}
        </div>

        {/* GOOGLE MAPS */}
        <div className="pt-20 -mb-14">
          <a
            href="https://www.google.com/maps/place/Alpine+Pro+Health+LLP/@13.0302033,80.211197,17.1z/data=!4m6!3m5!1s0x3a5263f35905838b:0xb8240d5cf9cba5c7!8m2!3d13.029968!4d80.208704!16s%2Fg%2F11k56k79bm?hl=en&entry=ttu&g_ep=EgoyMDI0MTExMS4wIKXMDSoASAFQAw%3D%3D"
            target="_blank"
            rel="noopener noreferrer"
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3890.5075484029953!2d80.211197!3d13.0302033!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5263f35905838b%3A0xb8240d5cf9cba5c7!2sAlpine%20Pro%20Health%20LLP!5e0!3m2!1sen!2sin!4v1709527044371!5m2!1sen!2sin"
              width="1000"
              height="600"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="w-full"
            ></iframe>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
