import WorkingProcessImg from '../../../public/assets/images/home/Our_Solution_Process.png';

export const ourSolutionProcessData = {
  image: WorkingProcessImg,
  topics: [
    {
      topicName: 'Identify the Problem',
      description:
        "The first critical step in our solution process for Alpine Pro Health is to identify a problem. This starts with a deep needs assessment so as to understand the challenges in the organization.",
    },
    {
      topicName: 'Define Objectives and Goals',
      description:
        'With an identified problem, we set certain measurable objectives that guide our efforts. These goals are carefully tailored to point toward the overall mission and vision of Alpine Pro Health. ',
    },
    {
      topicName: 'Develop a Plan',
      description:
        'An effective strategy will need to be underpinned by wide-ranging research into possible solutions and good practice. The options will have been considered to include upgrading technology, and policy changes.',
    },
    {
      topicName: 'Implement the Solution',
      description:
        'Implementation is when our plans come into action. For this, we form a well-structured, focused project team with predefined roles and responsibilities who will lead this implementation process. ',
    },
    
  ],
};
