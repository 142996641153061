import React,{useEffect} from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import "../src/react/styles/custom.css";
import "../src/react/styles/style.css";
import { ModalProvider } from "./react/context/ModalContext";
import HomeOne from "./react/components/homes/home/index.jsx";
import AboutPage from "./react/components/about/index.jsx";
import Contact from "./react/components/contact/index.jsx";
import Blogs from "./react/components/blogs/index.jsx";
import Header from "./react/components/layout/header/Header.jsx";
import Footer from "./react/components/layout/footer/Footer.jsx";
import Career from "./react/components/career/index.jsx";
import Services from "./react/components/services/index.jsx";
import SingleService from "./react/components/single-service/index.jsx";
import ScrollToTop from "./react/components/ScrollToTop.jsx";
import HireModal from "./react/components/modals/HireModal.jsx";
import PrivacyPage from "./react/app/(LayoutOther)/privacy-policy/page.jsx";
import SingleJobPage from "./react/app/(LayoutOther)/career/[job]/page.jsx";
import JobApplyModal from "./react/components/modals/JobApplyModal.jsx";

function App() {
  return (
    <ModalProvider>
      <Router>
        <LayoutWithHeader />
      </Router>
    </ModalProvider>
  );
}

function LayoutWithHeader() {
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  useEffect(() => {
    const pageData = {
      "/": {
        title: "Medical Coding Company With Best Services - Alpine Pro Health",
        description: "Alpine Pro Health provides a high-quality healthcare medical coding, with its services which include IPDRG, outpatient coding, risk adjustment, and physician quality reporting solutions.",
      },
      "/about-us": {
        title: "About Alpine Pro Health | Medical Coding ",
        description: "Welcome to Alpine Pro Health-Your Reliable Partner in Medical Coding Toward Innovative Solutions for Accurate Healthcare Management.",
      },
      "/contact": {
        title: "Contact Us - Alpine Pro Health",
        description: "Get in touch with Alpine Pro Health for top healthcare solutions. Our experts are ready to assist with medical coding, RCM, and compliance. Contact us today!.",
      },
      "/services": {
        title: "Expert Medical Coding Services | IPDRG, OP, HCC, PQRS & More",
        description: "Alpine Pro Health offers expert healthcare medical coding services, including IPDRG, outpatient coding, risk adjustment, and physician quality reporting solutions.",
      },
      "/privacy-policy": {
        title: "Privacy Policy - Alpine Pro Health",
        description: "Read about Alpine Pro Health's privacy policy and data protection practices.",
      },
      "/career": {
        title: "Career - Alpine Pro Health.",
        description: "Join Alpine Pro Health’s team to drive healthcare transformation. Improve coding accuracy, enhance revenue integrity, and impact patient outcomes globally.",
      },
      "/career/content-writer": {
        title: "Content Writer Job - Alpine Pro Health",
        description: "Apply for the Content Writer position at Alpine Pro Health.",
      },
      "/career/frontend-developer": {
        title: "Frontend Developer Job - Alpine Pro Health",
        description: "Apply for the Frontend Developer position at Alpine Pro Health.",
      },
      "/services/inpatient-medical-coding-services": {
        title: "Accurate & High-Quality Inpatient Coding | Alpine Pro Health",
        description: "Explore inpatient coding with E&M CPT codes, evaluation management codes, CPT codes for inpatient hospital, DRG medical codes, and in-patient CPT insights.",
      },
      "/services/outpatient-medical-coding-services": {
        title: "Expert Outpatient Coding Services | Alpine Pro Health",
        description: "Alpine Pro Health, offers expert outpatient coding services that prioritize accuracy and compliance, ensuring efficient revenue cycles and better healthcare.",
      },
      "/services/risk-adjustment": {
        title: "Trusted Risk Adjustment Coding Services | Alpine Pro Health",
        description: "Boost your revenue with risk adjustment coding from Alpine Pro Health. Align with accuracy for maximum reimbursement and compliance.",
      },
      "/services/value-added-services": {
        title: "Value Added Services Offered by Alpine Pro Health",
        description: "We offers value-added services like Transitional Care Management, Remote Patient Monitoring, and Chronic Care Management for better patient care.",
      },
      "/services/revenue-cycle-management": {
        title: "Healthcare revenue cycle management - Alpine Pro Health",
        description: "Alpine Pro Health optimizes healthcare revenue cycle management, enhancing efficiency, accuracy while ensuring compliance, leading to better financial results.",
      },
      "/services/physician-quality-reporting": {
        title: "Physician Quality Reporting Services | Alpine Pro Health",
        description: "Enhance your business with Alpine Pro Health's Physician Quality Reporting Services, ensuring accurate data submission and improved patient care outcomes.",
      },
    };

    const { title, description } = pageData[location.pathname] || {
      title: "Alpine Pro Health",
      description: "Welcome to Alpine Pro Health. Discover our offerings and get in touch.",
    };

    document.title = title;

    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute("content", description);
    } else {
      const meta = document.createElement("meta");
      meta.name = "description";
      meta.content = description;
      document.head.appendChild(meta);
    }
  }, [location.pathname]);


  return (
    <>
      <Header headerPrimary={isHomePage} />{" "}
      <Routes>
        <Route path="/" element={<HomeOne />} />

        <Route path="/about-us" element={<AboutPage />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services" element={<Services />} />
        <Route path="/privacy-policy" element={<PrivacyPage />} />
        <Route path="/career" element={<Career />} />
        <Route
          path="/career/content-writer"
          element={<SingleJobPage job={"content-writer"} />}
        />

        <Route
          path="/career/frontend-developer"
          element={<SingleJobPage job={"frontend-developer"} />}
        />

        <Route
          path="/career/backend-developer-node-(mid-level)"
          element={<SingleJobPage job={"backend-developer-node-(mid-level)"} />}
        />

        <Route
          path="/career/junior-devops-engineer"
          element={<SingleJobPage job={"junior-devops-engineer"} />}
        />

        <Route
          path="/career/sqa-engineer"
          element={<SingleJobPage job={"sqa-engineer"} />}
        />

        <Route
          path="/career/unreal-engine-developer"
          element={<SingleJobPage job={"unreal-engine-developer"} />}
        />

        {/* Service single page */}

        <Route
          path="/services/inpatient-medical-coding-services"
          element={
            <SingleService
              singleServiceName={"inpatient-medical-coding-services"}
            />
          }
        />
        <Route
          path="/services/outpatient-medical-coding-services"
          element={
            <SingleService
              singleServiceName={"outpatient-medical-coding-services"}
            />
          }
        />
        <Route
          path="/services/risk-adjustment"
          element={<SingleService singleServiceName={"risk-adjustment"} />}
        />
        <Route
          path="/services/value-added-services"
          element={<SingleService singleServiceName={"value-added-services"} />}
        />
        <Route
          path="/services/revenue-cycle-management"
          element={
            <SingleService singleServiceName={"revenue-cycle-management"} />
          }
        />
        <Route
          path="/services/physician-quality-reporting"
          element={
            <SingleService singleServiceName={"physician-quality-reporting"} />
          }
        />
        <Route path="/blog"  />
      </Routes>
      <ScrollToTop />
      <Footer />
      <HireModal />
      <JobApplyModal />
    </>
  );
}

export default App;
