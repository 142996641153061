'use client';

import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MdClose } from 'react-icons/md';
import NavBar from '../../layout/header/NavBar';
import { twMerge } from 'tailwind-merge';
import { HiOutlineMenuAlt3 } from 'react-icons/hi';
import useScroll from '../../../hooks/useScroll';
import useSticky from '../../../hooks/useSticky';
import LogoPrimary from '../../../public/assets/logo_15.png';
import LogoSecondary from '../../../public/assets/logo_14.png';

const Header = ({ headerPrimary }) => {
  const { sticky } = useSticky(150);
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);

  const isScrolled = useScroll(100);
  const isHomePage = location.pathname === "/";

  return (
    <header className="relative header">
      <div
        className={twMerge(
          'w-full absolute left-0 z-50 page-header',
          sticky && 'is-sticky',
          !headerPrimary && 'bg-white'
        )}
      >
        <div
          className={twMerge(
            'container flex justify-between items-center lg:gap-10 gap-6',
            sticky ? 'py-7 lg:py-0' : 'lg:py-0 py-[36px]'
          )}
        >
          {/*---------------------- LOGO START ----------------------*/}
          <Link to="/" className="w-48 grid place-items-center">
            <img
              src={isHomePage ? (isScrolled ? LogoPrimary : LogoSecondary) : LogoPrimary}
              width={200}
              height={100}
              alt={isHomePage ? (isScrolled ? 'Primary logo' : 'Secondary logo') : 'Primary logo'}
              className={twMerge(
                isHomePage && isScrolled ? 'w-48 mb-3' : 'rounded-lg'
              )}
            />
          </Link>
          {/*---------------------- LOGO END ----------------------*/}

          {/*---------------------- NAVBAR START ----------------------*/}
          <NavBar
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            pathname={location.pathname}
            headerPrimary={headerPrimary}
            sticky={sticky}
          />
          {/*---------------------- NAVBAR END ----------------------*/}

          <button
            className={twMerge(
              'text-4xl block lg:hidden',
              sticky || !headerPrimary ? 'text-black' : 'text-white'
            )}
            onClick={() => setMenuOpen((prev) => !prev)}
          >
            {menuOpen ? <MdClose /> : <HiOutlineMenuAlt3 />}
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
