import { servicesData } from '../../data/serviceData';
import { Service } from '../../components/Service';

const ServiceSection = () => {
  return (
    <section id="services" className=" py-24">
      <div className="container">
        {/* TITLE AND DESCRITPTION */}
        <div className="text-center pb-14 space-y-5">
          <h2 className="text-5xl font-bold">Our Special Services</h2>
          <p className="text-lg">
          At Alpine Pro Health, through our medical coding and billing specialty services, we strive to provide reliable, accurate ICD-10 CM Implementation to ensure your operations within the healthcare field maintain a smooth flow. From Error-Free Medical Coding to a complete Revenue Cycle Management service, we specialize in ICD-10 CM Implementation. As an expert in every aspect, we go ahead to offer Compliance Audit Services, Risk Adjustment, and Telehealth Coding, all under one roof. With services led by precision and reliability, we offer customized state-of-the-art solutions that are best suited to your specific healthcare requirements. Start and grow on your way to optimized healthcare operations with Alpine Pro Health.
          </p>
        </div>

        {/* SERVICES */}
        <ul className="grid xl:grid-cols-3 md:grid-cols-2 lg:gap-[40px] gap-7">
          {servicesData?.map((item, i) => (
            // SERVICE ITEM
            <Service
              key={i}
              serviceDescription={item.serviceDescription}
              serviceImage={item.serviceImage}
              serviceName={item.serviceName}
            />
          ))}
        </ul>
      </div>
    </section>
  );
};

export default ServiceSection;
