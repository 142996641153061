import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom

import Loader from "../../components/common/Loader";
import Title from "../../components/common/Title";
import { Breadcrumb } from "../../components/common/Breadcrumb";

import { careerData } from "../../data/careerData";
import JobInformation from "./JobInformation";
import useLoader from "../../hooks/useLoader";
import JobSummery from "./JobSummery";
import JoinOurTeam from "./JoinOurTeam";

const SingleJob = ({ jobName }) => {
  const loading = useLoader();
  const jobData = careerData?.jobs;
  const joinDynamicTeamData = careerData?.dynamicTeam;

  const [currentJob, setCurrentJob] = useState(null); // Initialize as null for clarity
  const navigate = useNavigate(); // Use react-router's useNavigate for navigation

  useEffect(() => {
    const job = jobData?.find((job) => {
      const exs = job?.title?.toLowerCase();
      const curr = jobName?.split("-")?.join(" ");
      return exs == curr;
    });
    setCurrentJob(job);
  }, [jobData, jobName]);

  useCallback(() => {
    if (currentJob === null) {
      // Redirect to a 404 page or show an error message if job is not found
      navigate("/404"); // Redirect to a 404 route
    }
  }, [currentJob, navigate]);

  if (loading) {
    return <Loader />;
  }

  if (!currentJob) {
    return <div>Loading...</div>; // Optional: You could also show a specific not found message
  }

  return (
    <div>
      <Breadcrumb title={jobName?.split("-")?.join(" ")} />

      <div className="py-20">
        <div className="container flex md:flex-row flex-col gap-10">
          <JobInformation currentJob={currentJob} />
          <JobSummery currentJob={currentJob} />
        </div>
      </div>

      <section className="py-20">
        <div className="container">
          <div className="max-w-3xl mx-auto">
            <Title
              title="How to Join Our Thriving Team?"
              subTitle="Become a Part of Alpine Pro Health by Following Our Streamlined Hiring Process."
              titleBlack={true}
              classNames="flex-col-reverse"
            />
          </div>
          <JoinOurTeam joinDynamicTeamData={joinDynamicTeamData} />
        </div>
      </section>
    </div>
  );
};

export default SingleJob;
