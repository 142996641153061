import SingleJob from '../../../../components/single-career';

const SingleJobPage = ({ job }) => {
  const jobName =job;

  return (
    <>
      <SingleJob jobName={jobName} />
    </>
  );
};

export default SingleJobPage;
