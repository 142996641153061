"use client";

import { useEffect, useRef, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { twMerge } from "tailwind-merge";
import Swal from "sweetalert2";
import Input, { Textarea } from "../../components/Input";
import { LoadingButton } from "../../components/Button";
import { useModal } from "../../context/ModalContext";
import Select from "../../components/Select";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { BsFileEarmarkFill } from "react-icons/bs";
import { MdClose } from "react-icons/md";
import ThumbsUp from "../../public/assets/images/career/thumbs-up.png";

function JobApplyModal() {
  const skills = [
    { value: "react.js", label: "React.js" },
    { value: "javaScript", label: "JavaScript" },
    { value: "python", label: "Python" },
    { value: "php", label: "PHP" },
    { value: "bootstrap", label: "Bootstrap" },
    { value: "django", label: "Django" },
    { value: "node.js", label: "Node.js" },
  ];

  const noticePeriodOpt = [
    { value: "Less than month", label: "Less than month" },
    { value: "One month", label: "One month" },
    { value: "Two months", label: "Two months" },
  ];

  const yearsOfExperienceOpt = [
    { value: "No Experience", label: "No Experience" },
    { value: "1 - 2 years", label: "1 - 2 years" },
    { value: "3 - 5 years", label: "3 - 5 years" },
    { value: "6 - 8 years", label: "6 - 8 years" },
    { value: "9 - 11 years", label: "9 - 11 years" },
    { value: "12 - 14 years", label: "12 - 14 years" },
    { value: "15 - 17 years", label: "15 - 17 years" },
    { value: "18 - 20 years", label: "18 - 20 years" },
  ];

  const defaultOpt = {
    value: "",
    label: "Please Select",
  };

  const initialData = {
    fullName: "",
    email: "",
    number: "",
    currentSalary: "",
    expectedSalary: "",
    noticePeriod: "",
    yearExperience: "",
    skills: [],
    position: "",
    portfolio: "",
    coverLetter: "",
    yourMessage: "",
    resumeFileName: "",
    resumeFile: null,
  };

  const [selectSkills, setSelectSkills] = useState([]);
  const [file, setFile] = useState(null);
  const [previewImg, setPreviewImg] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const { applyModalOpen, setApplyModalOpen, jobPosition } = useModal();
  const [formData, setFormData] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const [successSubmitted, setSuccessSubmitted] = useState(false);
  const [noticePeriod, setNoticePeriod] = useState("");
  const [yearExperience, setyearExperience] = useState("");

  const formRef = useRef(null);

  const handleFormChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      const allowedTypes = ["image/png", "image/jpeg", "application/pdf"];
      const maxSize = 5 * 1024 * 1024;

      if (uploadedFile.size > maxSize) {
        Swal.fire({
          icon: "error",
          title: "File size too large",
          text: "Please upload a file smaller than 5MB.",
        });
        return;
      }

      if (!allowedTypes.includes(uploadedFile.type)) {
        Swal.fire({
          icon: "error",
          title: "Invalid file type",
          text: "Only images (PNG, JPG) and PDF files are allowed.",
        });
        return;
      }

      setFile(uploadedFile);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formDataToSend = new FormData();
    formDataToSend.append("fullName", formData.fullName);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("number", formData.number);
    formDataToSend.append("currentSalary", formData.currentSalary);
    formDataToSend.append("expectedSalary", formData.expectedSalary);
    formDataToSend.append("noticePeriod", noticePeriod);
    formDataToSend.append("yearExperience", yearExperience);
    formDataToSend.append(
      "skills",
      selectSkills.map((skill) => skill.value).join(",")
    );
    formDataToSend.append("portfolio", formData.portfolio);
    formDataToSend.append("coverLetter", formData.coverLetter);
    formDataToSend.append("yourMessage", formData.yourMessage);

    if (file) {
      formDataToSend.append("resume", file);
    }

    try {
      const response = await fetch(
        "https://alpineprohealth.com/sharmi/apply_form.php",
        {
          method: "POST",
          body: formDataToSend,
        }
      );

      if (response.ok) {
        setSuccessSubmitted(true);
        formRef.current.reset();
        Swal.fire({
          icon: "success",
          title: "Form submitted successfully!",
          text: "Your application has been submitted.",
        });
      } else {
        console.error("Error submitting form");
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong, please try again later.",
        });
      }
    } catch (error) {
      console.error("Submission error", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong, please try again later.",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    formRef?.current?.reset();
    setPreviewImg(null);
    setSelectSkills([]);
    setSuccessSubmitted(false);
    return setApplyModalOpen(false);
  };

  useEffect(() => {
    if (
      formData.fullName &&
      formData.number &&
      formData.email &&
      formData.expectedSalary &&
      noticePeriod &&
      yearExperience &&
      selectSkills.length > 0 &&
      file
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [formData, noticePeriod, yearExperience, selectSkills, file]);

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setPreviewImg(reader.result);
      reader.readAsDataURL(file);
    }
  }, [file]);

  return (
    <div>
      {successSubmitted && (
        <div
          className={twMerge(
            "fixed z-50 inset-0 transition-all duration-500 hide-scrollbar grid place-items-center",
            !successSubmitted ? "opacity-0 invisible" : "opacity-100 visible"
          )}
        >
          <div
            className="fixed inset-0 transition-opacity"
            onClick={handleClose}
          >
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <div className="max-w-2xl w-full m-auto grid place-items-center p-12 gap-4 bg-white rounded-md shadow-md relative">
            <button
              onClick={handleClose}
              className="absolute right-4 top-4 text-gray-700"
            >
              <MdClose className="text-4xl" />
            </button>

            <img src={ThumbsUp} alt="thumbs-up" className="w-24 h-24" />

            <h4 className="text-3xl font-medium">Thanks for the Apply!</h4>
            <p className="text-darkGray text-lg">
              We will contact with you shortly.
            </p>
          </div>
        </div>
      )}

      <div
        style={{ scrollbarWidth: "none" }}
        className={twMerge(
          "fixed z-50 inset-0 transition-all duration-500 hide-scrollbar",
          !applyModalOpen ? "opacity-0 invisible" : "opacity-100 visible"
        )}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="fixed inset-0 transition-opacity"
            onClick={handleClose}
          >
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full relative">
            <button
              onClick={handleClose}
              className="absolute right-4 top-4 text-gray-700"
            >
              <MdClose className="text-4xl" />
            </button>

            <div className="bg-white sm:p-12 p-8 pt-14">
              {/* TITLE START */}
              <h2 className="text-4xl leading-6 font-bold text-center text-primary sm:mb-12 mb-6">
                Fill the job applications
              </h2>

              <form onSubmit={handleSubmit} ref={formRef}>
                {/* TEXT INPUTS */}
                <div className="grid md:grid-cols-3 md:gap-6">
                  <Input
                    type="Fullname"
                    id="fullName"
                    label="Your Full Name"
                    placeholder="Jhon"
                    errorMessage="This field is required!"
                    onChange={handleFormChange}
                    required
                  />

                  <Input
                    type="email"
                    id="email"
                    label="Your Email Address"
                    placeholder="example@gmail.com"
                    errorMessage="This field is required!"
                    onChange={handleFormChange}
                    required
                  />

                  <Input
                    type="text"
                    id="number"
                    label="Your Phone Number"
                    placeholder="+123456789"
                    errorMessage="This field is required!"
                    onChange={handleFormChange}
                    required
                  />
                </div>

                <div className="grid md:grid-cols-3 md:gap-6">
                  <Input
                    type="text"
                    id="currentSalary"
                    label="Current Salary"
                    placeholder="type here"
                    onChange={handleFormChange}
                  />
                  <Input
                    type="text"
                    id="expectedSalary"
                    label="Expected Salary"
                    placeholder="type here"
                    errorMessage="This field is required!"
                    onChange={handleFormChange}
                    required
                  />

                  <div>
                    <h3 className="mb-2 text-sm font-medium">
                      Available to start work/Notice period?
                      <span className="text-red-500">*</span>
                    </h3>

                    <Select
                      options={noticePeriodOpt}
                      defaultOption={defaultOpt}
                      selectedValue={noticePeriod}
                      errorMessage="This field is required!"
                      onChange={(e) => setNoticePeriod(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="grid md:grid-cols-3 md:gap-6">
                  <div>
                    <h3 className="mb-2 text-sm font-medium">
                      Total Years of Experience
                      <span className="text-red-500">*</span>
                    </h3>

                    <Select
                      options={yearsOfExperienceOpt}
                      defaultOption={defaultOpt}
                      selectedValue={yearExperience}
                      errorMessage="This field is required!"
                      onChange={(e) => setyearExperience(e.target.value)}
                      required
                    />
                  </div>

                  {/* <div className="">
                    <h3 className="mb-2 text-sm font-medium">
                      Skills <span className="text-red-500">*</span>
                    </h3>

                    <MultiSelect
                      options={skills}
                      value={selectSkills}
                      onChange={setSelectSkills}
                      labelledBy="Select Skills"
                      className="z-0"
                      required
                    />
                  </div> */}
                  <div className="">
                    <Input
                      type="text"
                      id="portfolio"
                      label="Portfolio (Optional)"
                      placeholder="URL"
                      onChange={handleFormChange}
                    />
                  </div>
                </div>

                {/* TEXT AREA */}
                <div className="grid  md:gap-6">
                  <Textarea
                    style={{ resize: "none" }}
                    id="coverLetter"
                    label="Cover Letter (Optional)"
                    placeholder="Type your cover letter here..."
                    onChange={handleFormChange}
                  />
                </div>
                <div className="grid  md:gap-6 w-full">
                  <Textarea
                    style={{ resize: "none" }}
                    id="yourMessage"
                    label="Your Message (Optional)"
                    placeholder="Type your message here..."
                    onChange={handleFormChange}
                  />
                </div>

                {/* Resume Upload Section */}
                <div className="mt-4">
                  <label className="block mb-2 font-bold">
                    Upload Resume (Optional)
                  </label>
                  <div className="flex items-center gap-4">
                    <input
                      htmlFor="resume"
                      type="file"
                      id="resume"
                      onChange={handleFileChange}
                      accept=".jpg,.jpeg,.png,.pdf"
                      className="hidden"
                      required
                    />
                    <label
                      htmlFor="resume"
                      className="flex items-center cursor-pointer bg-gray-100 p-3 rounded-lg border border-gray-300"
                    >
                      <BsFileEarmarkFill className="mr-2 text-primary" />
                      Upload Resume
                    </label>

                    {file && (
                      <div className="mt-2">
                        {file.type === "application/pdf" ? (
                          <div className="flex items-center space-x-2">
                            <AiOutlineCloudUpload className="text-primary text-2xl" />
                            <span className="text-sm text-gray-700">
                              {file.name} (PDF)
                            </span>
                          </div>
                        ) : (
                          <img
                            src={previewImg}
                            alt="File Preview"
                            className="w-32 h-32 object-cover rounded-lg"
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>

                {/* SUBMIT BUTTON */}
                <div className="flex items-center gap-8 justify-between mt-5">
                  {loading ? (
                    <LoadingButton />
                  ) : (
                    <button
                      type="submit"
                      className={twMerge(
                        "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white sm:w-auto sm:text-sm cursor-pointer",
                        disabled
                          ? "bg-gray-400"
                          : "bg-primary hover:bg-indigo-600"
                      )}
                      // disabled={disabled}
                    >
                      Submit
                    </button>
                  )}

                  <button
                    onClick={handleClose}
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 sm:mt-0 sm:w-auto sm:text-sm"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobApplyModal;
