import WebsiteImgae from '../public/assets/images/service/Inpatient_5.png';
import AppImgae from '../public/assets/images/service/Value_Added_Service_4.png';
import SoftwareImgae from '../public/assets/images/service/Outpatients_4.png';
import GameImgae from '../public/assets/images/service/Risk_Adjustment_4.png';
import AiImage from '../public/assets/images/service/Physician_Quality_Reporting_3.png';
import DigitalAgencyImage from '../public/assets/images/service/Revenue_Cycle_Management_4.png';


import WebService1 from '../public/assets/images/service/web-dev/IPDRG_4.jpeg';
import WebService2 from '../public/assets/images/service/web-dev/E&M_IP_2.jpeg';
import WebService3 from '../public/assets/images/service/web-dev/Anesthesia_2.jpeg';
import WebService4 from '../public/assets/images/service/web-dev/General_Surgery_2.jpeg';
import WebService5 from '../public/assets/images/service/web-dev/corporate-web-development.png';
import WebService6 from '../public/assets/images/service/web-dev/all-other-web-development.png';
import WebService7 from '../public/assets/images/service/web-dev/all-other-web-development.png';
import WebService8 from '../public/assets/images/service/web-dev/all-other-web-development.png';
import WebService9 from '../public/assets/images/service/web-dev/all-other-web-development.png';
import WebService10 from '../public/assets/images/service/web-dev/all-other-web-development.png';
import WebService11 from '../public/assets/images/service/web-dev/IVR.png';
import WebService12 from '../public/assets/images/service/web-dev/all-other-web-development.png';

import AiService1 from '../public/assets/images/service/ai/physical.jpeg';
import AiService2 from '../public/assets/images/service/ai/natural-language.png';
import AiService3 from '../public/assets/images/service/ai/image-rec.png';
import AiService4 from '../public/assets/images/service/mobile-app-dev/MIPS_1.jpeg';
import AiService5 from '../public/assets/images/service/ai/pattern-recognition.png';
import AiService6 from '../public/assets/images/service/mobile-app-dev/hedis_1.jpeg';
import AiService7 from '../public/assets/images/service/ai/alexa.png';
import AiService8 from '../public/assets/images/service/ai/automated.png';
import AiService9 from '../public/assets/images/service/ai/vertual.png';
import AiService10 from '../public/assets/images/service/ai/robot.png';

import SoftwareService1 from '../public/assets/images/service/software-dev/SDS.jpeg';
import SoftwareService2 from '../public/assets/images/service/software-dev/custom-web.png';
import SoftwareService3 from '../public/assets/images/service/software-dev/ED.jpeg';
import SoftwareService4 from '../public/assets/images/service/software-dev/payment-getway.png';
import SoftwareService5 from '../public/assets/images/service/software-dev/E_D.jpeg';
import SoftwareService6 from '../public/assets/images/service/software-dev/social-feed.png';
import SoftwareService7 from '../public/assets/images/service/software-dev/Radiology.jpeg';
import SoftwareService8 from '../public/assets/images/service/software-dev/Pathalogy.jpeg';

import GameService1 from '../public/assets/images/service/game-dev/restro.jpeg';
import GameService2 from '../public/assets/images/service/game-dev/prospective.jpeg';
import GameService3 from '../public/assets/images/service/game-dev/concurrent.jpeg';
import GameService4 from '../public/assets/images/service/game-dev/radv.jpeg';

import AppService1 from '../public/assets/images/service/mobile-app-dev/TransionalCare.jpeg';
import AppService2 from '../public/assets/images/service/mobile-app-dev/remoteMonitor.jpeg';
import AppService3 from '../public/assets/images/service/mobile-app-dev/chronic.jpeg';
import AppService4 from '../public/assets/images/service/mobile-app-dev/homeHealth.jpeg';
import AppService5 from '../public/assets/images/service/mobile-app-dev/princiCare.jpeg';
import AppService6 from '../public/assets/images/service/mobile-app-dev/hosPiceCoding_1.jpeg';
import AppService7 from '../public/assets/images/service/mobile-app-dev/MIPS_1.jpeg';
import AppService8 from '../public/assets/images/service/mobile-app-dev/hedis_1.jpeg';
import AppService9 from '../public/assets/images/service/mobile-app-dev/Oasis.jpeg';

import DigitalService1 from '../public/assets/images/service/digital-agency/id-card.png';
import DigitalService2 from '../public/assets/images/service/digital-agency/logo-design.png';
import DigitalService3 from '../public/assets/images/service/digital-agency/t-shirt-design.png';
import DigitalService4 from '../public/assets/images/service/digital-agency/custom-theme-developmet.png';
import DigitalService5 from '../public/assets/images/service/digital-agency/plugin-development.png';
import DigitalService6 from '../public/assets/images/service/digital-agency/seo.png';
import DigitalService7 from '../public/assets/images/service/digital-agency/smm.png';

export const servicesData = [
  {
    serviceId: 101,
    serviceName: 'Inpatient Medical Coding Services',
    serviceImage: WebsiteImgae,
    serviceDescription:
      'Inpatient Coding Services provide for accurate classification of diagnoses and procedures on hospitalized patients. These services ensure that medical records are transformed into standardized codes, which again help with appropriate billing, regulatory compliance, and efficient revenue cycle management. Accurate inpatient coding boosts reimbursement, reduces mistakes, and, in turn, increases better and easier core operations within the hospitals, something absolutely necessary for healthcare providers.',
    serviceCategory: 'Inpatient Medical Coding Services',
    subServices: [
      {
        subServiceId: 201,
        subServiceName: 'IPDRG',
        subServiceDetails: [
          {
            image: WebService1,
            title: 'IPDRG',
            description:
              "IPDRG improves coding accuracy, reimbursement, compliance, and financial results in a hospital setting.",
          },
        ],
      },
      {
        subServiceId: 202,
        subServiceName: 'E&M IP',
        subServiceDetails: [
          {
            image: WebService2,
            title: 'E&M IP',
            description:
              "E&M IP increases quality documentation; thus, raises coding accuracy, reimbursement, and compliance for inpatient services.",
          },
        ],
      },
      {
        subServiceId: 203,
        subServiceName: 'Anesthesia',
        subServiceDetails: [
          {
            image: WebService3,
            title: 'Anesthesia',
            description:
              "Anesthesia as a service ensures proper billing; hence, its compliance, good reimbursement, and effective management of healthcare.",
          },
        ],
      },
      {
        subServiceId: 204,
        subServiceName: 'General Surgery',
        subServiceDetails: [
          {
            image: WebService4,
            title: 'General Surgery',
            description:
              "General Surgery coding enhances coding for accuracy, increases reimbursement, supports compliance, and is best suited for the outcome of patient care.",
          },
        ],
      },
      {
        subServiceId: 211,
        subServiceName: 'IVR(Interventional Radiology)',
        subServiceDetails: [
          {
            image: WebService11,
            title: 'IVR(Interventional Radiology)',
            description:
              '',
          },
        ],
      },
    ],
    serviceFAQs: [
      {
        id: 1,
        title: 'What is the difference between front-end and back-end development?',
        description:
          "Front-end development refers to the part of web development that focuses on the user interface and user experience. It involves designing and implementing the visual elements of a website that users interact with. Back-end development, on the other hand, involves server-side programming and database management. It's responsible for the functionality and behind-the-scenes operations of a website.",
      },
      {
        id: 2,
        title: 'What is responsive web design, and why is it important?',
        description:
          "Responsive web design is an approach to design and development that ensures a website's layout and content adjust dynamically based on the device or screen size. This ensures a consistent and optimal user experience across various devices, such as desktops, tablets, and smartphones. It is important because it improves accessibility, user satisfaction, and search engine rankings, as search engines prioritize mobile-friendly websites.",
      },
      {
        id: 3,
        title: 'What is the significance of website security, and how can it be ensured?',
        description:
          'Website security is crucial to protect user data, prevent unauthorized access, and maintain the integrity of a site. To ensure website security, developers can implement HTTPS encryption, regularly update software and plugins, use secure coding practices, employ firewalls, and conduct regular security audits. Additionally, user authentication and authorization mechanisms should be robust to prevent unauthorized access.',
      },
      {
        id: 4,
        title: 'What is the role of content management systems (CMS) in website development?',
        description:
          'Content Management Systems (CMS) are platforms that allow users to easily create, manage, and modify digital content on a website without requiring technical expertise. CMS simplifies the process of content creation and editing, making it more accessible for non-technical users. Popular CMS options include WordPress, Drupal, and Joomla, each offering various features and customization options.',
      },
      {
        id: 5,
        title:
          'What are the key factors affecting website loading speed, and how can it be optimized?',
        description:
          "Website loading speed is influenced by factors such as large images, excessive HTTP requests, inefficient code, and server response time. To optimize loading speed, developers can compress images, minimize HTTP requests, use browser caching, and employ techniques like lazy loading for images. Additionally, optimizing code, utilizing Content Delivery Networks (CDNs), and choosing a reliable hosting provider can significantly improve a website's performance.",
      },
    ],
  },

  {
    serviceId: 104,
    serviceName: 'Outpatient Medical Coding Services',
    serviceImage: SoftwareImgae,
    serviceDescription:
      'Coding services for outpatient facilities have helped to keep diagnoses and procedures in order for those patients who do not need to be confined within the walls of a hospital. These services keep the medical billing process accurate, ensure that all regulations are followed, and hasten the revenue cycles. Outpatient coding transforms medical information into the essential code facilitating increased reimbursement and lesser errors for good financial health of any medical facility.',
    serviceCategory: 'Outpatient Medical Coding Services',
    subServices: [
      {
        subServiceId: 201,
        subServiceName: 'SDS(Same Day Surgery)',
        subServiceDetails: [
          {
            image: SoftwareService1,
            title: 'SDS(Same Day Surgery)',
            description: 'Same-day surgery in outpatient coding: Improves Productivity Ensures Proper Billing Enhances Reimbursement.',
          },
          // {
          //   image: SoftwareService2,
          //   title: 'ED Professional & Facility',
          //   description: 'ED Professional & Facility.',
          // },
        ],
      },
      {
        subServiceId: 202,
        subServiceName: 'ED Professional & Facility',
        subServiceDetails: [
          {
            image: SoftwareService3,
            title: 'ED Professional & Facility',
            description: 'ED Provider and Facility coding will improve accuracy, increase revenue, ensure compliance and enhance patient care.',
          },
          // {
          //   image: SoftwareService4,
          //   title: 'Payment Gateway Integration',
          //   description: 'Secure integration of payment methods for online transactions.',
          // },
        ],
      },
      {
        subServiceId: 203,
        subServiceName: 'E&M OP',
        subServiceDetails: [
          {
            image: SoftwareService5,
            title: 'E&M OP',
            description: 'E&M OP coding Improves the accuracy of documentation for better reimbursement, compliance, and streamlines out-patient care.',
          },
          // {
          //   image: SoftwareService6,
          //   title: 'Real-time Social Feeds',
          //   description: 'Instant updates of social interactions and content.',
          // },
        ],
      },
      {
        subServiceId: 204,
        subServiceName: 'Radiology',
        subServiceDetails: [
          {
            image: SoftwareService7,
            title: 'Radiology',
            description:
              'Radiology in out-patient medical coding plays a vital role in accurate diagnosis, billing, and compliance for optimal benefits towards effective patient care.',
          },
          // {
          //   image: SoftwareService8,
          //   title: 'CRM',
          //   description: 'Our CRM solutions, expertly crafted to meet unique business needs.',
          // },
        ],
      },
      {
        subServiceId: 205,
        subServiceName: 'Lab & Pathology',
        subServiceDetails: [
          {
            image: SoftwareService8,
            title: 'Lab & Pathology',
            description:
              'Lab and pathology coding ensures accurate reimbursement, compliance, and hence enhances patient care for effective management of data.',
          },
          // {
          //   image: SoftwareService8,
          //   title: 'CRM',
          //   description: 'Our CRM solutions, expertly crafted to meet unique business needs.',
          // },
        ],
      },
    ],
    serviceFAQs: [
      {
        id: 1,
        title: 'What is software development, and why is it important?',
        description:
          "Software development is the process of designing, coding, testing, and maintaining software applications. It's crucial because software powers many aspects of modern life, from business operations to entertainment and communication, making it a cornerstone of technological progress.",
      },
      {
        id: 2,
        title: 'What are the common programming languages used in software development?',
        description:
          "Several programming languages are commonly used in software development, including Java, Python, C++, JavaScript, and C#. The choice of language depends on the specific project's requirements and goals.",
      },
      {
        id: 3,
        title: 'What is the software development life cycle (SDLC), and why is it significant?',
        description:
          "The Software Development Life Cycle (SDLC) is a structured approach to software development, consisting of phases like planning, design, coding, testing, deployment, and maintenance. It's significant because it provides a framework for organized and efficient development, ensuring that the end product meets user needs and quality standards.",
      },
      {
        id: 4,
        title: 'What are the key challenges in software development?',
        description:
          'Challenges in software development include managing project scope, meeting deadlines, ensuring software security, dealing with changing requirements, and maintaining code as technologies evolve. Effective project management and best practices help address these challenges.',
      },
      {
        id: 5,
        title:
          'How can I stay up to date with the latest trends and technologies in software development?',
        description:
          'To stay current, consider reading industry publications, participating in online developer communities, attending conferences, and taking online courses. Continuous learning is essential in a field that evolves rapidly.',
      },
    ],
  },
  {
    serviceId: 105,
    serviceName: 'Risk Adjustment',
    serviceImage: GameImgae,
    serviceDescription:
      'The work of Risk Adjustment Coding or HCC coding is to ensure that providers get appropriate compensation by applying payment adjustments due to the complexity and chronic condition of the patients. It aids in enhancing care management, supports value-based care models, and aids in the identification of high-risk patients. Accurate coding will bring proper reimbursement, hence improves patient outcomes, following all standards of health compliance. This serves as one of the prime elements for effective revenue cycle management.',
    serviceCategory: 'Risk Adjustment',
    subServices: [
      {
        subServiceId: 201,
        subServiceName: 'Retrospective Reviews',
        subServiceDetails: [
          {
            image: GameService1,
            title: 'Retrospective Reviews',
            description: 'Retrospective reviews in risk adjustment coding make sure that the documentation is accurate enough to help out in the reimbursement process and quality care.',
          },
          // {
          //   image: GameService2,
          //   title: 'Prospective Reviews',
          //   description: 'Prospective reviews in risk adjustment coding identify documentation gaps early, improving compliance and reimbursement accuracy.',
          // },
        ],
      },
      {
        subServiceId: 202,
        subServiceName: 'Prospective Reviews',
        subServiceDetails: [
          {
            image: GameService2,
            title: 'Prospective Reviews',
            description: 'Prospective reviews in risk adjustment coding identify documentation gaps early, improving compliance and reimbursement accuracy.',
          },
        ],
      },
      {
        subServiceId: 203,
        subServiceName: 'Concurrent Reviews',
        subServiceDetails: [
          {
            image: GameService3,
            title: 'Concurrent Reviews',
            description: 'Prospective reviews in risk adjustment coding ensure that there is an early identification of the gaps in documentation and improves the compliance and accuracy level in the reimbursement process.',
          },
        ],
      },
      {
        subServiceId: 204,
        subServiceName: 'RADV Audits',
        subServiceDetails: [
          {
            image: GameService4,
            title: 'RADV Audits',
            description: 'RADV audits in risk adjustment coding validate the accuracy of diagnosis and ensure the requirement is in compliance so that the reimbursement potential is maximized.',
          },
        ],
      },
    ],
    serviceFAQs: [
      {
        id: 1,
        title: 'What is game development, and what are the key stages of the process?',
        description:
          'Game development is the process of creating video games, encompassing various stages like concept development, design, coding, testing, and final deployment. Each stage involves a range of tasks and skills, including art, programming, sound design, and quality assurance.',
      },
      {
        id: 2,
        title: 'What programming languages and tools are commonly used in game development?',
        description:
          'Popular programming languages for game development include C++, C#, and Python. Game engines like Unity, Unreal Engine, and Godot provide comprehensive toolsets for game developers, simplifying the development process.',
      },
      {
        id: 3,
        title: 'What are the major challenges in game development?',
        description:
          'Challenges in game development include optimizing performance across various platforms, creating engaging gameplay experiences, handling complex 3D graphics, managing game project scope, and ensuring compatibility with multiple devices and operating systems.',
      },
      {
        id: 4,
        title:
          'How can I get started in game development, and what skills are essential for success?',
        description:
          'Getting started in game development often involves learning a programming language, gaining proficiency in a game engine, and creating a portfolio of projects. Essential skills include coding, 2D and 3D art, sound design, storytelling, and a deep understanding of player psychology.',
      },
      {
        id: 5,
        title: 'What are the different roles in a game development team?',
        description:
          'A game development team typically includes roles like game designers, programmers, artists (2D and 3D), sound designers, quality assurance testers, project managers, and producers. The size and composition of the team can vary depending on the scope of the project.',
      },
    ],
  },
  {
    serviceId: 106,
    serviceName: 'Value Added Services',
    serviceImage: AppImgae,
    serviceDescription:
      "Value Added Services refer to complementary services that add value to the core and differentiated offerings of the business from its competitors. Examples include customized support, enriched analytics, and solutioning, among others, that amplify customer satisfaction and loyalty. Value-added services can create extra value by addressing specific client needs for business long-term relationships and growth in the competitive market.",
    serviceCategory: 'Value Added Services',
    subServices: [
      {
        subServiceId: 201,
        subServiceName: 'Transitional Care Management',
        subServiceDetails: [
          {
            image: AppService1,
            title: 'Transitional Care Management',
            description:
              'Transitional care management improves outcomes by ensuring coordination of services, preventing readmissions, and enabling continuous care.',
          },
        ],
      },
      {
        subServiceId: 202,
        subServiceName: 'Remote Patient Monitoring',
        subServiceDetails: [
          {
            image: AppService2,
            title: 'Remote Patient Monitoring',
            description: 'Distant Monitoring of the Patient Improves Health Care Service. Enhances Involvement of Patients While also Fostering Proactive Health Management.',
          },
          // {
          //   image: AppService3,
          //   title: 'clothing',
          //   description:
          //     'Developing mobile apps for clothing retail with easy browsing and checkout.',
          // },
        ],
      },
      {
        subServiceId: 203,
        subServiceName: 'Chronic Care Management',
        subServiceDetails: [
          {
            image: AppService3,
            title: 'Chronic Care Management',
            description: 'Chronic Care Management Optimizes Patient Outcomes through Personalized Care Plans and Continuous Health Monitoring Supports.',
          },
          // {
          //   image: AppService5,
          //   title: 'attendence',
          //   description:
          //     'Creating attendance tracking apps for educational and organizational purposes.',
          // },
        ],
      },
      {
        subServiceId: 204,
        subServiceName: 'Principle Care Management',
        subServiceDetails: [
          {
            image: AppService4,
            title: 'Principle Care Management',
            description: 'Principle Care Management ensures better patient outcomes with personalized care coordination and best-in-class health management services.',
          },
        ],
      },
      {
        subServiceId: 205,
        subServiceName: 'Home Health Services',
        subServiceDetails: [
          {
            image: AppService5,
            title: 'Home Health Services',
            description: `Home Health Services: Personalized recovery and independence in one's home.`,
          },
        ],
      },
      {
        subServiceId: 206,
        subServiceName: 'Hospice Coding Services',
        subServiceDetails: [
          {
            image: AppService6,
            title: 'Hospice Coding Services',
            description: `Care improvement and compliance for specialized hospice coding services to help secure accurate reimbursement.`,
          },
        ],
      },
      {
        subServiceId: 209,
        subServiceName: 'OASIS',
        subServiceDetails: [
          {
            image: AppService9,
            title: 'OASIS',
            description: `Implementation of OASIS assessments to enhance patient outcomes, advanced care planning, as well as maintaining compliance with regulation.`,
          },
        ],
      },
    ],
    serviceFAQs: [
      {
        id: 1,
        title: 'What is mobile app development, and why is it important?',
        description:
          "Mobile app development involves creating software applications for mobile devices such as smartphones and tablets. It's important because mobile apps have become essential tools for businesses to engage with their customers, streamline operations, and expand their reach in the digital space.",
      },
      {
        id: 2,
        title: 'What are the primary platforms for mobile app development?',
        description:
          'The two main platforms for mobile app development are Android and iOS. Android apps are developed using Java or Kotlin, while iOS apps are typically built with Swift or Objective-C. Cross-platform development tools like React Native and Flutter also enable developers to create apps for both platforms simultaneously.',
      },
      {
        id: 3,
        title: 'How long does it take to develop a mobile app?',
        description:
          "The time required to develop a mobile app varies significantly depending on the complexity of the app, its features, and the team's expertise. Simple apps might take a few months, while more complex ones can take a year or longer. Proper planning, design, and project management are key to meeting timelines.",
      },
      {
        id: 4,
        title: 'What are the essential steps in mobile app development?',
        description:
          'The key steps in mobile app development include idea conceptualization, market research, design and prototyping, development, testing, deployment, and ongoing maintenance. Each phase is crucial to delivering a successful mobile app.',
      },
      {
        id: 5,
        title: 'How do I choose between native and cross-platform app development?',
        description:
          'The choice between native and cross-platform development depends on factors like your target audience, project requirements, budget, and development timeline. Native apps often offer better performance but may require separate development for iOS and Android. Cross-platform development can be more cost-effective and faster but may have some performance trade-offs.',
      },
    ],
  },
  {
    serviceId: 107,
    serviceName: 'Revenue Cycle Management',
    serviceImage: DigitalAgencyImage,
    serviceDescription:
      "At Alpine Pro Health, we enhance Revenue Cycle Management (RCM) through a streamlined process led by our efficient coders. Our RCM services include appointment scheduling, patient registration, and thorough eligibility verification. We conduct utilization reviews and manage referrals and authorizations, ensuring accurate RCM coding. Charge posting, claim submissions, and clearing house denials are handled meticulously. Our denial management, secondary filing, and accounts receivable processes, combined with effective patient billing and collections, ensure optimal revenue flow in today's complex healthcare landscape.",
      serviceCategory: 'Revenue Cycle Management',
    subServices: [
      // {
      //   subServiceId: 201,
      //   subServiceName: 'RCM',
      //   subServiceDetails: [
      //     {
      //       image: DigitalService1,
      //       title: 'RCM',
      //       description: '',
      //     },
      //     // {
      //     //   image: DigitalService2,
      //     //   title: 'logo design',
      //     //   description: 'Crafting unique and impactful logos for brand identity.',
      //     // },
      //     // {
      //     //   image: DigitalService3,
      //     //   title: 't-Shirt design',
      //     //   description: 'Creating engaging t-shirt designs for various occasions.',
      //     // },
      //   ],
      // },
    //   {
    //     subServiceId: 202,
    //     subServiceName: 'wordpress development',
    //     subServiceDetails: [
    //       {
    //         image: DigitalService4,
    //         title: 'Custom Theme Development',
    //         description:
    //           'Craft unique, branded WordPress themes for a distinct website look and feel tailored to your needs.',
    //       },
    //       {
    //         image: DigitalService5,
    //         title: 'Plugin Development',
    //         description:
    //           'Build custom WordPress plugins for exclusive website functionalities and integrations.',
    //       },
    //     ],
    //   },

    //   {
    //     subServiceId: 204,
    //     subServiceName: 'DGM (Digital Marketing)',
    //     subServiceDetails: [
    //       {
    //         image: DigitalService6,
    //         title: 'SEO (Search Engine Optimization)',
    //         description:
    //           'Enhance website visibility on search engines and boost organic traffic through on-page and off-page optimization.',
    //       },
    //       {
    //         image: DigitalService7,
    //         title: 'SMM (Social Media Marketing)',
    //         description:
    //           'Build a strong online presence, engage with the audience, and promote your brand through various social media platforms.',
    //       },
    //     ],
    //   },
    ],
  },
  {
    serviceId: 103,
    serviceName: 'Physician Quality Reporting',
    serviceImage: AiImage,
    serviceDescription:
      'Physician Quality Reporting is a Medicare program that financially rewards health professionals for reporting on certain quality measures. Information collected in the system will help improve patient care and outcomes. Such providers who fulfill the requirements of reporting may receive financial incentives, thereby relating compensation with the quality of care delivered in attempts to add continuous improvement in the healthcare provided.',
    serviceCategory: 'Physician Quality Reporting',
    subServices: [
      // {
      //   subServiceId: 201,
      //   subServiceName: 'Physician Quality Reporting',
      //   subServiceDetails: [
      //     {
      //       image: AiService1,
      //       title: 'Physician Quality Reporting',
      //       description: 'How Alpine Pro Health ensures accurate Physician Quality Reporting, enhancing compliance, reimbursement, and overall healthcare quality.',
      //     },
      //     // {
      //     //   image: AiService2,
      //     //   title: 'Natural Language Processing',
      //     //   description: 'Interact with computers through human language understanding.',
      //     // },
      //     // {
      //     //   image: AiService3,
      //     //   title: 'Image Recognition',
      //     //   description: "AI's visual comprehension for various applications.",
      //     // },
      //   ],
      // },
      {
        subServiceId: 202,
        subServiceName: 'MIPS',
        subServiceDetails: [
          {
            image: AiService4,
            title: 'MIPS',
            description: '',
          },
          // {
          //   image: AiService5,
          //   title: 'Pattern Recognition',
          //   description: 'Identify patterns in complex data for valuable insights.',
          // },
        ],
      },
      {
        subServiceId: 203,
        subServiceName: 'HEDIS',
        subServiceDetails: [
          {
            image: AiService6,
            title: 'HEDIS',
            description: '',
          },
          // {
          //   image: AiService7,
          //   title: 'Amazon Alexa',
          //   description: 'AI voice assistant enhancing smart living and convenience.',
          // },
        ],
      },
      // {
      //   subServiceId: 204,
      //   subServiceName: 'Robotic Process Automation',
      //   subServiceDetails: [
      //     {
      //       image: AiService8,
      //       title: 'Automated Workflows',
      //       description: 'Efficiently automate repetitive tasks for enhanced productivity.',
      //     },
      //     {
      //       image: AiService9,
      //       title: 'Virtual Assistants',
      //       description: 'AI-powered assistants to streamline and enhance daily tasks.',
      //     },
      //     {
      //       image: AiService10,
      //       title: 'robot',
      //       description: 'Integrating AI for automating physical processes and tasks.',
      //     },
      //   ],
      // },
    ],
    serviceFAQs: [
      {
        id: 1,
        title: 'What is AI integration, and why is it important for businesses?',
        description:
          "AI integration involves incorporating artificial intelligence capabilities and algorithms into existing systems or applications to enhance their functionality. It's important for businesses because it can automate tasks, improve decision-making, and enhance customer experiences, ultimately leading to increased efficiency and competitiveness.",
      },
      {
        id: 2,
        title: 'What are some common use cases for AI integration in business?',
        description:
          'AI integration can be applied in various domains, such as customer service (chatbots and virtual assistants), data analysis (predictive analytics and data-driven insights), automation (robotic process automation), and personalization (recommendation engines).',
      },
      {
        id: 3,
        title: 'How do I determine if my business needs AI integration?',
        description:
          'Assess your business processes and goals. If you have tasks that are repetitive, data-heavy, or require data-driven decision-making, AI integration can be beneficial. Consider whether AI can improve efficiency, reduce costs, or enhance the customer experience.',
      },
      {
        id: 4,
        title: 'What challenges should businesses be aware of when integrating AI?',
        description:
          "Challenges include data privacy and security concerns, the need for skilled AI talent, the potential for biases in AI models, and the cost of AI implementation. It's crucial to plan for these challenges and implement robust AI governance practices.",
      },
      {
        id: 5,
        title: 'What steps should a business take to successfully integrate AI?',
        description:
          'Start with a clear strategy, identifying specific use cases and expected outcomes. Collect and prepare high-quality data, choose the right AI tools or platforms, and invest in training for your team. Collaborate with AI experts and continually monitor and fine-tune AI models for optimal performance.',
      },
    ],
  },
];
