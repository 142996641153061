import React from "react";
import { Link } from "react-router-dom"; // Use react-router-dom for navigation
import { FaMapMarkerAlt } from "react-icons/fa";
import { MdLocationCity, MdMail } from "react-icons/md";
import { IoCall } from "react-icons/io5";
import { BsFillPhoneVibrateFill } from "react-icons/bs";
import SocialButton from "../../SocialButton";
import Logo from "../../../public/assets/logo_14.png"; // Update the path if necessary
import { footerMenuItems } from "../../layout/footer/footerMenuItems";

const Footer = () => {
  // Social media links
  const socialLinks = [
    {
      href: "https://www.linkedin.com/company/alpine-pro-health-llp/",
      name: "linkedin",
      icon: <FaMapMarkerAlt />, // Add the icon here
    },
    {
      href: "https://www.instagram.com/alpine_pro_health/",
      name: "instagram",
      icon: <FaMapMarkerAlt />,
      classNames: "text-[#E4405F] hover:bg-[#E4405F]",
    },
    {
      href: "https://www.facebook.com/alpineprohealth",
      name: "facebook",
      icon: <FaMapMarkerAlt />,
    },
  ];

  // Address details
  const addressDetails = [
    {
      branchName: "Phase 1",
      text: "97, 98, Level 1, Magna Square, Jawaharlal Nehru Rd, Ashok Nagar, Chennai, Tamil Nadu, 600083, India",
      icon: FaMapMarkerAlt,
    },
    {
      branchName: "Phase 2",
      text: "No.81/81, Level 1, Vellalar St, Mogappair West, Chennai, Tamil Nadu 600037, India",
      icon: MdLocationCity,
    },
  ];

  const ContactDetails = [
    {
      icon: MdMail,
      text: "info@alpineprohealth.com",
      href: "/",
    },
    {
      icon: IoCall,
      text: "IND : +91 8519899754",
      href: "/",
    },
    {
      icon: BsFillPhoneVibrateFill,
      text: "US : +1(914)-292-9596",
      href: "/",
    },
  ];

  return (
    <footer className={`text-white font-extralight footer`} style={{ fontFamily: "sans-serif" }}>
      <div className="container py-14">
        
        <div className="flex flex-col sm:flex-row justify-between items-start flex-nowrap w-full gap-12">
        
          <div className="flex-1 min-w-[200px]">
            <img src={Logo} alt="Logo" className="w-48 mb-3" width={200} height={100} />
            <p className="sm:text-lg">
              Alpine Pro Health is a highly experienced provider of Revenue Cycle
              Management processes and analytics solutions in the healthcare industry.
            </p>

            <ul className="flex items-center gap-5 mt-6">
              {socialLinks.map((link, i) => (
                <SocialButton
                  key={i}
                  icon={link?.icon}
                  href={link?.href}
                  name={link?.name}
                  classNames={link?.classNames}
                />
              ))}
            </ul>
          </div>

          <div className="flex-1 min-w-[200px]">
            <h5 className="text-2xl mb-[22px] bottom-0 border-b-2 border-solid pb-1 w-fit font-light">
              Useful Links
            </h5>
            <ul className="list-disc list-inside sm:text-lg footer-nav-list">
              {footerMenuItems.companyLinks?.map((link, index) => (
                <li key={index}>
                  {link.href.startsWith('http') ? (
                    
                    <a href={link.href} target="_blank" rel="noopener noreferrer" className="text-white hover:text-primary">
                      {link.text}
                    </a>
                  ) : (
                    <Link to={link.href} className="text-white hover:text-primary">
                      {link.text}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </div>
          {/* Useful Links */}

          {/* Address Section */}
          <div className="flex-1 min-w-[200px]">
            <h5 className="text-2xl mb-[22px] bottom-0 border-b-2 border-solid pb-1 w-fit font-light">
              Where Are We
            </h5>
            <div className="sm:text-lg space-y-3">
              {addressDetails.map((line, index) => (
                <div key={index} className="flex flex-col gap-2">
                  {/* Display the branch name */}
                  {line.branchName && <h3 className="font-semibold">{line.branchName}</h3>}
                  <div className="flex gap-2">
                    {/* Display the icon if available */}
                    {line?.icon && (
                      <span>
                        <line.icon className="text-3xl" />
                      </span>
                    )}
                    {/* Render the address text with or without href */}
                    {line?.href ? (
                      <a href={line.href} className="text-white-500" style={{ cursor: "default" }}>
                        <p>{line.text}</p>
                      </a>
                    ) : (
                      <p>{line.text}</p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Address */}

          {/* Support Section */}
          <div className="flex-1 min-w-[200px]">
            <h5 className="text-2xl mb-[22px] bottom-0 border-b-2 border-solid pb-1 w-fit font-light">
              Contact
            </h5>
            <div className="sm:text-lg space-y-3">
              {ContactDetails.map((line, index) => (
                <div key={index} className="flex gap-2">
                  {/* Display the icon if available */}
                  {line?.icon && (
                    <span>
                      <line.icon className="text-3xl" />
                    </span>
                  )}
                  {/* Render the address text with or without href */}
                  {line?.href ? (
                    <a href={line.href} className="text-white-500" style={{ cursor: "default" }}>
                      <p>{line.text}</p>
                    </a>
                  ) : (
                    <p>{line.text}</p>
                  )}
                </div>
              ))}
            </div>
          </div>
          {/* Support */}
        </div>
      </div>

      {/* Copyright */}
      <div className={`py-3 px-5 flex justify-center items-center container text-white text-center sm:text-lg border-t border-gray-500 border-solid`}>
        <p>
          <span className="font-serif">&copy;</span> {new Date().getFullYear()} by Alpine. All Rights Reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
