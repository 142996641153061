import { useState, useEffect } from "react";
import swal from "sweetalert2";
import Input, { Textarea } from "../Input";
import Select from "../Select";

const ContactForm = () => {
  const defaultOpt = {
    value: "",
    label: "Department",
  };

  const departmentOptions = [
    { value: "Support and Service", label: "Support and Service" },
    { value: "Product", label: "Product" },
    { value: "Others", label: "Others" },
  ];

  const [departmentSelect, setDepartmentSelect] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    department: "",
  });

  const sendEmail = (e) => {
    e.preventDefault();
  
    const url = `https://alpineprohealth.com/sharmi/send_email2.php?email=${(formData.email)}&subject=${encodeURIComponent(formData.subject)}&message=${encodeURIComponent(formData.message)}&department=${encodeURIComponent(formData.department)}&name=${encodeURIComponent(formData.name)}`;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Success:", data);
  
        swal.fire({
          title: "Success!",
          text: "Your message has been sent.",
          icon: "success",
          confirmButtonText: "OK",
        });
  
        setFormData({
          name: "",
          email: "",
          subject: "",
          message: "",
          department: "",
        });
        setDepartmentSelect("");
      })
      .catch((error) => {
        console.error("Error:", error);
  
        swal.fire({
          title: "Error!",
          text: "There was a problem sending your message. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };
  

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSelectChange = (e) => {
    const { value } = e.target;
    setDepartmentSelect(value);
    setFormData((prevData) => ({
      ...prevData,
      department: value,
    }));
  };

  return (
    <div>
      <div className="space-y-3">
        <h4 className="text-3xl font-medium">SEND A MESSAGE</h4>
        <p className="text-lg text-darkGray">
          Your email address will not be published. Required fields are marked.
        </p>
      </div>

      <hr className="w-[85px] h-px bg-darkGray mt-5 mb-10" />

      <form className="grid grid-cols-1 w-full" onSubmit={sendEmail}>
        <div className="grid grid-cols-2 gap-6 w-full">
          <Input
            id="name"
            type="text"
            placeholder="Full Name*"
            errorMessage="This field is required!"
            classNames="px-5 py-3 w-full"
            required
            value={formData.name}
            onChange={handleChange}
          />
          <Input
            id="email"
            type="email"
            placeholder="Email Address*"
            errorMessage="This field is required!"
            classNames="px-5 py-3 w-full"
            required
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <Input
          id="subject"
          type="text"
          placeholder="Subject*"
          errorMessage="This field is required!"
          classNames="px-5 py-3 w-full"
          required
          value={formData.subject}
          onChange={handleChange}
        />

        <Select
          classNames="px-5 py-3 w-full mb-6"
          options={departmentOptions}
          defaultOption={defaultOpt}
          selectedValue={departmentSelect}
          onChange={handleSelectChange}
        />

        <Textarea
          name="message"
          id="message"
          placeholder="Your Message"
          classNames="px-5 py-3 w-full rounded-md"
          value={formData.message}
          onChange={handleChange}
        />

        <button
          type="submit"
          className="btn btn-secondary text-white w-full text-lg tracking-wider rounded-md"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
